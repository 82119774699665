<!-- eslint-disable -->
<template>
  <div id="wrap" style="position: absolute;display:block;width: 100%; height: -webkit-fill-available;">
    <div class="container" style="height:100%;">
      <div class="gis" style="height:100%;">
        <ul class="map-layer"></ul>
        <div id="map" class="gis-map" style="height:100%;">
          <div id="featureTooltipInfo"></div>

          <!-- <ul class="map-type-buld">
            <li data="TL_SPBD_BULD" class="on">
              <div>행안부_도로명주소</div>
            </li>
            <li data="AL_D010">
              <div>국토부_GIS건물통합정보</div>
            </li>
            <li data="F_FAC_BULD">
              <div>국토부_건물통합정보마스터</div>
            </li>
            <li data="TB_BULD">
              <div>국토부_바로e맵</div>
            </li>
          </ul> -->
          <!--
          <ul class="map-type">
            <li data="Naver"   >
              <img src="/gis/map/common/images/gis/navermap.png" width="40" height="40" alt="Naver"/>
              <div>네이버</div>
            </li>
            <li data="Daum">
              <img src="/gis/map/common/images/gis/kakaomap.png" width="40" height="40" alt="Daum"/>
              <div>카카오</div>
            </li>
            <li data="VWorld">
              <img src="/gis/map/common/images/gis/vworldmap.jpg" width="40" height="40" alt="VWrold"/>
              <div>브이월드</div>
            </li>
            <li class="on" data="ollehmap">
              <img src="/gis/map/common/images/gis/ollehmap.png" width="40" height="40" alt="ollehmap"/>
              <div>올레</div>
            </li>
            <li data="baroEmap" style="width:42px; height:62px;padding-top:2px;overflow:hidden;">
              <img src="http://map.ngii.go.kr/images_renew/common/h1.png" alt="baroEmap"/>
              <div style="padding-top:1px;">바로e맵</div>
            </li>
          </ul> -->
          <div>
            <div
              class="bgMapApiListBtn"
              @click="
                () => {
                  bgMapApiListFlag = !bgMapApiListFlag;
                }
              "
            >
              <img class="icon" src="/gis/map/common/images/gis/icon_btn_api.png" alt="iconApi" />
            </div>
            <div v-show="bgMapApiListFlag" class="bgMapApiList" @click="changeBgMap">
              <!-- <div class="infoLabel">배경지도<br />(API)</div> -->
              <div bgMapApi="RoadAddrMap" class="bgMap">
                <img class="icon" id="icon_roadaddr" src="/gis/map/common/images/gis/icon_roadaddrmap.png" style="width: 38px;height: 38px;object-fit: contain;" />
                <span class="apiLabel on">
                  도로명
                </span>
              </div>
              <div bgMapApi="KakaoMap" class="bgMap">
                <img class="icon" id="icon_kakao" src="/gis/map/common/images/gis/icon_kakaomap_off.png" style="width: 38px;height: 38px;object-fit: contain;" />
                <span class="apiLabel">
                  카카오
                </span>
              </div>
              <div bgMapApi="VworldMap" class="bgMap">
                <img class="icon" id="icon_vworld" src="/gis/map/common/images/gis/icon_vworldmap_off.png" style="width: 38px;height: 38px;object-fit: contain;" />
                <span class="apiLabel">
                  브이월드
                </span>
              </div>
              <div bgMapApi="KtMap" class="bgMap">
                <img class="icon on" id="icon_ktmap" src="/gis/map/common/images/gis/icon_ktmap_off.png" style="width: 38px;height: 38px;object-fit: contain;" />
                <span class="apiLabel">
                  KT
                </span>
              </div>
              <div bgMapApi="BaroEMap" class="bgMap">
                <img class="icon" id="icon_baroemap" src="/gis/map/common/images/gis/icon_baroemap_off.png" style="width: 38px;height: 38px;object-fit: contain;" />
                <span class="apiLabel">
                  국토정보
                </span>
              </div>
            </div>
          </div>

          <div>
            <div class="buldLayerListBtn" @click="buldLayerBtnClk">
              <img class="icon" src="/gis/map/common/images/gis/icon_btn_building.png" alt="iconBuilding" />
            </div>
            <div v-show="buldLayerFlag" class="buldLayerList">
              <!-- <div class="infoLabel">
								배경건물
							</div> -->
              <div class="buldLayer">
                <!-- <select id="buldLayers">
									<option value="TL_SPBD_BULD">행정안전부_건물정보(도로명주소시스템)</option>
									<option value="AL_D010">국토부_GIS건물정보(국가공간정보포털_오픈API)</option>
									<option value="F_FAC_BULD">국토부_건물통합정보마스터(국가공간정보포털_오픈마켓)</option>
									<option value="TB_BULD">국토부_건물영역정보(국토지리정보원_바로e맵)</option>
								</select> -->
                <div class="buldLayerItemWrap" @click="changeBuldLayerVal">
                  <input type="hidden" id="buldLayers" value="TL_SPBD_BULD" />
                  <div class="buldLayersItem" id="TL_SPBD_BULD" style="background:#4441D4;">
                    <span class="buldLayerTitle" style="color:white;">행정안전부<br />건물정보</span><span class="buldLayerExp" style="color:white;">도로명주소<br />시스템</span>
                  </div>
                  <div class="buldLayersItem" id="AL_D010">
                    <span class="buldLayerTitle">국토부<br />GIS건물정보</span><span class="buldLayerExp">국가공간정보포털<br />오픈API</span>
                  </div>
                  <div class="buldLayersItem" id="F_FAC_BULD">
                    <span class="buldLayerTitle">국토부<br />건물통합정보마스터</span><span class="buldLayerExp">국가공간정보포털<br />오픈마켓</span>
                  </div>
                </div>
                <!-- <div class="buldLayerItem">
									<span>행정안전부<br>건물정보</span>
									<span>도로명주소<br>시스템</span>
								</div> -->
              </div>
            </div>
          </div>

          <!-- <div
              style="position: absolute;right: 22px;top: 22px;z-index: 100;
						width: 30x;height: 22px;line-height: 20px;text-align: center;
						font-size: 15px;color: #fff;background-color: #1ced24;
						border: 1px solid #5e6368;border-radius: 3px;cursor: default;"
              onClick="gis.search.goto3DMap(map);"
          >
            2.5D
          </div> -->
          <div class="map-level">5</div>
          <div class="btn-area">
            <div class="group" style="margin-bottom: 2px;border-radius: 10px;border: 0px;">
              <button id="selectArea" class="btn-tooltip" type="button">
                <img id="lassoToolsIcon" alt="올가미" src="/gis/map/common/images/gis/icon_btn_lasso.png" />
                <!-- <span class="msg left">올가미</span> -->
              </button>
              <!-- <ul id="lassoTools" style="display:none;position: absolute;margin-top: 0px; "> -->
              <div id="lassoTools" style="display:none;">
                <!-- <li class="selmenu" style="margin-top: 0px;" @click="changeLasso"> -->
                <!-- <span class="selmenu" style="margin-top: 0px;" > -->
                <span class="selmenu">
                  <div id="pointAreaSelect" class="btn-tooltip lassoToolsItem" title="개별 선택">
                    <!-- <img alt="개별선택" src="/gis/map/common/images/gis/building_off.png" /> -->
                    <img id="lasso_building" alt="개별선택" class="lassoIconSelector" src="/gis/map/common/images/gis/renew_icon_building_off.png" />
                    <span class="lassoMsg">개별</span>
                  </div>
                  <div id="circleAreaSelect" class="btn-tooltip lassoToolsItem" title="원형 선택">
                    <!-- <img alt="원형선택 " src="/gis/map/common/images/gis/circle_off.png" /> -->
                    <img id="lasso_circle" alt="원형선택" class="lassoIconSelector" src="/gis/map/common/images/gis/renew_icon_circle_off.png" />
                    <span class="lassoMsg">원형</span>
                  </div>
                  <div id="boxAreaSelect" class="btn-tooltip lassoToolsItem" title="사각형 선택">
                    <!-- <img alt="사각형선택 " src="/gis/map/common/images/gis/squre_off.png" /> -->
                    <img id="lasso_square" alt="사각형선택" class="lassoIconSelector" src="/gis/map/common/images/gis/renew_icon_square_off.png" />
                    <span class="lassoMsg">사각</span>
                  </div>
                  <div id="polygonAreaSelect" class="btn-tooltip lassoToolsItem" title="폴리곤 선택">
                    <!-- <img alt="폴리곤선택 " src="/gis/map/common/images/gis/polygon_off.png" /> -->
                    <img id="lasso_polygon" alt="폴리곤선택" class="lassoIconSelector" src="/gis/map/common/images/gis/renew_icon_polygon_off.png" />
                    <span class="lassoMsg">다각</span>
                  </div>
                  <div id="fullAreaSelect" class="btn-tooltip lassoToolsItem" title="화면 전체영역 선택">
                    <!-- <img alt="전체화면영역 선택" src="/gis/map/common/images/gis/all_off.png" /> -->
                    <img id="lasso_all" alt="전체화면영역 선택" class="lassoIconSelector" src="/gis/map/common/images/gis/renew_icon_all_off.png" />
                    <span class="lassoMsg">전체</span>
                  </div>
                </span>
                <span>
                  <div id="lassoBuildInfoSearch" class="btn-tooltip lassoToolsSearch" title="선택된 건물 정보 조회">
                    <!-- <img id="lasso_search" alt="선택된 건물 정보 조회" src="/gis/map/common/images/gis/lasso_build_search_dis.png" /> -->
                    <div id="lasso_search" alt="선택된 건물 정보 조회" class="lassoToolsSearchLabel" style="">건물을 선택해주세요</div>
                  </div>
                </span>
              </div>
              <!--
              <ul id="lassoToolsSubMenu" style="display:none;" >
                <li><span>건물선택중(on)</span></li>
                <li><span id="lassoBuildInfoSearch">건물정보조회</span></li>
                <li><span id="lassoToolsCancel">올가미취소</span></li>
              </ul>
              -->
            </div>
          </div>
        </div>
      </div>

      <div class="inhegaBtn">
        <button id="searchBld" class="btn-tooltip" @click="onClickSearchBld">
          <img alt="인허가 검색 버튼" src="/gis/map/common/images/gis/icon_btn_search.png" />
        </button>
      </div>
      <div
        style="position: absolute;
							right: 90px;
              top: 241px;
              z-index: 9;
              background: white;
              border: thin solid #cac9d0;
              border-radius: 10px;
              padding: 10px;
              width: 380px;
              height: 120px;"
        v-show="inputSearch"
      >
        <input-search-component :input-placeholder="inputPlaceholder" />
      </div>

      <div class="guideText">
        <span>해당서비스는 법적효력이 없으며 참고 자료로만 활용이 가능합니다</span>
      </div>

      <!-- <div id="loading">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div id="modal_sel" class="popLayer draggable" style="width:270px; height: fit-content;">
        <dl class="popCntType">
          <dt>
            선택영역 건물정보
          </dt>
          <dd class="popCnts" style="padding : 20px 20px 20px 20px !important;"></dd>
        </dl>
        <a
          class="btnClosePop"
          href="#"
          onclick="gis.common.hideModal({popID : 'modal_sel', find : '.popCnts'});return false;"
          ><img alt="팝업 닫기" src="/gis/map/common/images/btn_close_pop.png"
        /></a>
      </div>

      <div id="modal_all" class="popLayer draggable" style="width:270px; height: fit-content;">
        <dl class="popCntType">
          <dt>
            전체영역 장비개수
          </dt>
          <dd class="popCnts" style="padding : 20px 20px 20px 20px !important;"></dd>
        </dl>
        <a
          class="btnClosePop"
          href="#"
          onclick="gis.common.hideModal({popID : 'modal_all', find : '.popCnts', imgID : '#sel_all', imgFile : './common/images/gis/icon_all.png'});return false;"
          ><img alt="팝업 닫기" src="/gis/map/common/images/btn_close_pop.png"
        /></a>
      </div> -->
    </div>
    <!-- end of container div -->

    <!--  건물 정보 : S -->
    <div>
      <b-modal
        v-model="modalBuildInfo"
        centered
        ok-only
        ok-title="확인"
        size="xl"
        hide-footer
        :title="`건물 에너지 광역검진정보 (소재지: ${buildTotalInfo.bldAddr} / ${buildTotalInfo.bldNm}) `"
      >
        <div class="gradeDiv">
          <div class="left-div"></div>
          <div class="right-div" style="float:right;">
            <span v-if="this.mapngGrade !== ''">
              <b-badge style="font-size: 12px;" :variant="mapngGrade <= 24 ? 'success' : mapngGrade <= 43 ? 'warning' : 'danger'">
                <span>{{ mapngGrade }}등급</span>
              </b-badge>
              <span style="padding: 5px;">{{ layerKorName }}</span>
            </span>
            <span v-else>
              <img alt="loading" src="/gis/map/gis/images/common/loading.gif" />
            </span>

            <span class="menuHelp" @mouseout="mout" @mouseover="mover">
              <img src="@/assets/images/logo/help.png" />
            </span>
            <span v-show="helpBoxShow" class="menuHelpBox">
              <b>{{ mapngGrade }}등급</b> {{ mapngGradeDesc }}
              <!--              <img src="/gis/map/gis/images/common/bld_grade.png" />-->
            </span>
          </div>
        </div>

        <build-total-main :mgm-bld-pk="mgmBldPk" />
        <!--        <b-tabs class="modal-style" content-class="mt-2" justified>
          <b-tab active title="건축물 대장">
            <build-basic-info-component :mgm-bld-pk="mgmBldPk" />
          </b-tab>
          <b-tab title="층별 개요">
            <build-floor-overview-component :mgm-bld-pk="mgmBldPk" />
          </b-tab>
          <b-tab title="인허가 리스트">
            <build-license-list-component :mgm-bld-pk="mgmBldPk" />
          </b-tab>
        </b-tabs>-->
      </b-modal>
    </div>
    <!--  건물 정보 : E -->
    <!-- 동별/시군구별 통계 팝업-->
    <div>
      <b-modal v-model="modalMapAreaInfo" :title="areaTitle" centered hide-footer ok-title="확인" size="xl">
        <map-gis-area-table-component :dong-id="dongId" :map-type="mapType" :sigungu-id="sigunguId" />
        <div class="text-right">
          <b-button
            class="mt-1 mr-1"
            variant="success"
            style="background-color: #1F4529 !important; color: #ffffff !important; border-radius: 5px  !important;"
            @click="mapAreaExcel"
          >
            엑셀 다운로드
          </b-button>

          <b-button class="mt-1" variant="primary" @click="() => (modalMapAreaInfo = false)">닫기</b-button>
        </div>
      </b-modal>
    </div>
    <!-- <div>
      <b-modal v-model="modalMapAreaInfo" :title="areaTitle" centered ok-only ok-title="확인" size="lg">
        <map-gis-area-table-component :dong-id="dongId" :map-type="mapType" :sigungu-id="sigunguId" />
      </b-modal>
    </div> -->
    <!--    에너지 사용랑-->
    <div>
      <b-modal v-model="modalEnergyInfo" centered ok-only ok-title="확인" size="xl" title="▶ 건물별 에너지소비량">
        <b-tabs class="modal-style" content-class="mt-2" justified>
          <b-tab active title="에너지 소비량">
            <energy-use-info-component :mgm-bld-pk="mgmBldPk" />
          </b-tab>
          <b-tab title="계량기 정보">
            <energy-meter-info-component :mgm-bld-pk="mgmBldPk" />
          </b-tab>
        </b-tabs>
      </b-modal>
    </div>

    <!-- 신재생 에너지 -->
    <div>
      <b-modal v-model="modalNewEnergyInfo" centered ok-only ok-title="확인" size="lg" title="▶ 신재생에너지 포텐셜">
        <new-energy-component :mgm-bld-pk="mgmBldPk" />
      </b-modal>
    </div>
    <!-- 건물 정보 수정 제안   -->
    <div>
      <b-modal ref="my-modal" v-model="modalBuildInfoEditOffer" centered ok-only ok-title="확인" size="default" title="▶ 건물 정보 수정 제안" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <build-info-edit-offer :mgm-bld-pk="mgmBldPk" />
        </form>
      </b-modal>
    </div>

    <!-- KBOSS 설문조사 -->
    <div>
      <b-modal
        v-model="modalKbossInfo"
        id="kbossPopup"
        centered
        no-close-on-esc
        hide-backdrop
        ok-only
        ok-title="닫기"
        size="m"
        :title="kbossTitle"
        no-close-on-backdrop
        no-enforce-focus
      >
        <kboss-component :mgm-bld-pk="mgmBldPk" :lyr-id-str="lyrIdStr" :key="lyrIdStr" @floorData="floorData" />
      </b-modal>
    </div>

    <!-- 슬라이드 저장  -->
    <div v-if="this.buildClickTempInfo">
      <map-gis-temp-build-info-component-new :bld-info-data="bldInfoData" />
    </div>
    <!-- 올가미 -->
    <div>
      <b-modal v-model="modalLassoInfo" centered ok-only ok-title="확인" size="xl" title="▶ 올가미 선택 팝업">
        <build-lasso-info-component :mgm-bld-pk-lasso="mgmBldPkLasso" />
      </b-modal>
    </div>
    <!-- PDF modal -->
    <div>
      <b-modal v-model="modalPdfBEPA" centered ok-only ok-title="확인" size="lg" title="▶ BEPA 리포트">
        <pdf-bepa-component :mgm-bld-pk="mgmBldPk"></pdf-bepa-component>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { BBadge, BButton, BModal, BTab, BTabs, VBModal, VBToggle } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import BuildFloorOverviewComponent from '@/views/building/buildBaiscInfo/BuildFloorOverviewComponent';
import BuildBasicInfoComponent from '@/views/building/buildBaiscInfo/BuildBasicInfoComponent';
import BuildLicenseListComponent from '@/views/building/buildBaiscInfo/BuildLicenseListComponent';
import MapGisAreaTableComponent from '@/views/building/MapGisAreaTableComponent';

import EnergyUseInfoComponent from '@/views/building/EnergyUse/EnergyUseInfoComponent';
import EnergyMeterInfoComponent from '@/views/building/EnergyUse/EnergyMeterInfoComponent';
import MapGisTempBuildInfoComponentNew from '@/views/building/MapGisTempBuildInfoComponentNew';
import BuildLassoInfoComponent from '@/views/building/buildBaiscInfo/BuildLassoInfoComponent';
import NewEnergyComponent from '@/views/building/newEnergyUse/NewEnergyComponent';
import KbossComponent from '@/views/building/KbossComp/KbossComponent';
import { mapGetters } from 'vuex';
import BuildInfoEditOffer from '@/views/building/buildInfoEditOffer/buildInfoEditOffer';
import { utils } from '@/utils/commUtils';
import PdfBepaComponent from '~/building/pdfBepaComponent.vue';
import BuildTotalMain from '~/building/buildTotalInfo/buildTotalMain.vue';
import InputSearchComponent from '~/building/InputSearchComponent.vue';

export default {
  components: {
    InputSearchComponent,
    BuildTotalMain,
    PdfBepaComponent,
    BuildInfoEditOffer,
    BuildLassoInfoComponent,
    MapGisTempBuildInfoComponentNew,
    MapGisAreaTableComponent,

    BModal,

    BuildLicenseListComponent,
    BuildFloorOverviewComponent,
    BuildBasicInfoComponent,
    BTabs,
    BTab,
    EnergyMeterInfoComponent,
    EnergyUseInfoComponent,

    NewEnergyComponent,
    KbossComponent,

    BBadge,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      // 전역변수 클릭 여부
      buildClickTempInfo: false,

      modalBuildInfo: false,
      modalMapAreaInfo: false,
      modalEnergyInfo: false,
      modalLassoInfo: false,
      modalNewEnergyInfo: false,
      modalBuildInfoEditOffer: false, // 건물 정보 수정 제안
      modalPdfBEPA: false, // PDF View
      modalBuildTotal: false, // 건물 정보 토탈
      modalKbossInfo: false,
      modalKbossSurvey: false,
      modalKbossSensor: false,
      modalKbossFloorZone: false,

      isStarred: false,

      mgmBldPk: null, // 건물 PK
      mgmBldPkLasso: null, // 올가미로 묶은 건물 PK 배열
      areaTitle: null, // modal의 타이틀값
      mapType: null, // 시군구 or 읍면동 구분값(sigungu, dong)
      sigunguId: null, // 시군구 PK
      dongId: null, // 동 PK

      // 건물 정보 임시 저장 배열
      mgmBldPkArr: [],

      commonEnergy: {},
      purpsArr: [],

      helpBoxShow: false,

      mapngGrade: '',
      mapngGradeDesc: '',
      layerKorName: '',

      bldInfoData: {},

      // 건물명, 건물 주소
      buildTotalInfo: {},
      floorInfo: '',
      lyrIdStr: '',
      kbossTitle: '',

      inputPlaceholder: '동단위로 검색해주세요',

      bgMapApiListFlag: false,
      buldLayerFlag: false,
    };
  },

  computed: {
    ...mapGetters({
      mainSelected: 'main/getSelectedCodeData',
      buildInfoEditOffer: 'main/getBuildInfoEditOffer',
      inputSearch: 'main/getInputSearch',
    }),
  },
  mounted() {
    map = gis.map.init({ mapId: 'map' }); // ol.Map 객체 생성 및 배경지도 추가
    gis.map.background.setChangeEvt(map, '.bgMapApiList'); // 배경지도 교체 (네이버, 올레, 다음, 브이월드) 이벤트 셋팅
    gis.map.background.setBuldLayerChangeEvt(map, '#buldLayers'); // 배경건물 교체 (도로명_건물, 국토부_건물, 바로e맵 건물) 이벤트 셋팅

    gis.map.control.init(map, {
      menuToggle: 'span.btn-menuToggle',
      zoomLvl: 'div.map-level',
      zoomIn: '#zoomIn',
      zoomOut: '#zoomOut',
      clear: '#clear',
      lassoSelectArea: '#selectArea', // 올가미
      lassoPointAreaSelect: '#pointAreaSelect',
      lassoCircleAreaSelect: '#circleAreaSelect',
      lassoBoxAreaSelect: '#boxAreaSelect',
      lassoPolygonAreaSelect: '#polygonAreaSelect',
      lassoFullAreaSelect: '#fullAreaSelect',
      lassoBuildInfoSearch: '#lassoBuildInfoSearch',
      lassoToolsCancel: '#lassoToolsCancel',
    }); // 지도 기본 컨트롤 초기화

    const gisLayerTree = new gis.menu.LayerTree(); // 레이어 트리 생성
    contextMenu = new gis.menu.ContextMenu({
      MENU_ID: 'MAP_POP',
    }).load(); // 지도 콘텍스트 메뉴 생성

    gis.map.event.init(); // 지도 이벤트 초기화

    gis.search.init(map); // 검색기능 임시 레이어
    gis.map.layer.addTempLayers(map); // 임시레이어 추가
    // gis.map.layer.addExternalSystemServiceLayers(map); //외부시스템서비스레이어(참조용)

    // 현재위치초기화
    let inputMapInitData = null;
    if (this.$route.query.lng != null) {
      inputMapInitData = {
        allForLand: {
          centorLon: Number(this.$route.query.lng),
          centorLat: Number(this.$route.query.lat),
          zoom: Number(this.$route.query.zoom),
        },
      };
    }

    if (inputMapInitData != null && inputMapInitData.allForLand != null) {
      // 올포랜드(3D맵에서 제공해주는 데이터가 있다면 해당 위치로 이동)
      gis.map.setCenter(map, {
        centerCoord: [inputMapInitData.allForLand.centorLon, inputMapInitData.allForLand.centorLat],
        oldCrsCode: 'EPSG:4326',
        newCrsCode: 'EPSG:5179',
        zoomLvl: parseInt(inputMapInitData.allForLand.zoom) - 5,
      });
    } else {
      // 없다면 쿠키에서가져오기(default)
      const mapInit = gis.common.cookie.getJson('mapInit');
      if (mapInit != null) {
        map.getView().setZoom(mapInit.zoomLvl);
        map.getView().setCenter(mapInit.center);
      }
    }
    Fn.Map.debug();

    // 	gis.common.getLyrOptnInfo({mode : "js"});  //레이어 옵션 설정가져오기

    $('#map').on('mousewheel', function(e) {
      this.focus();
      e.preventDefault();

      let delta = 0;

      const E = e.originalEvent;
      if (E.detail) {
        delta = E.detail * -40;
      } else {
        delta = E.wheelDelta;
      }

      let updown;
      if (delta < 0) {
        updown = 'down';
      } else {
        updown = 'up';
      }

      if (updown === 'up') {
        gis.map.control.zoomIn();
      } else {
        gis.map.control.zoomOut();
      }
    });

    $('.ol-zoom-in, .ol-zoom-out').on('click', function() {
      let zoom;
      zoom = '';
      if (this.className === 'ol-zoom-in') {
        zoom = 'in';
        gis.map.control.zoomIn();
      } else {
        zoom = 'out';
        gis.map.control.zoomOut();
      }

      // gis.map.control.zoomLvCheck({zoom : zoom});
    });
  },
  methods: {
    getVueAppApiUrl() {
      //	console.log(process.env.VUE_APP_API_URL);
      return process.env.VUE_APP_API_URL;
    },

    async onClickBuildInfo(bldInfoData) {
      gis.search.moveToBldGeom(map, bldInfoData);

      // 전역변수 true
      this.buildClickTempInfo = true;

      this.mapngGrade = '';
      this.layerKorName = '';

      // this.mapngGrade = bldInfoData.mapngGrade
      // this.layerKorName = bldInfoData.layerKorName
      // if (typeof this.mapngGrade === "undefined" || this.mapngGrade === "") {
      //   this.mapngGrade = "-"
      //   this.layerKorName = "등급판정불가"
      // }
      // this.modalBuildInfo = !this.modalBuildInfo;

      // modal 타이틀에 건물정보, 주소를 입력하기 위해 db 조회
      const res = await this.$store.dispatch('main/FETCH_BUILD_TOTAL_TITLE_DATA', {
        mgmBldPk: bldInfoData.mgmBldPk,
      });
      if (res) {
        this.modalBuildTotal = !this.modalBuildTotal;
        this.buildTotalInfo.bldNm = res.bldnm != null ? res.bldnm : '-';
        this.buildTotalInfo.bldAddr = res.roadplataddr != null ? res.roadplataddr : '-';
        this.modalBuildInfo = !this.modalBuildInfo;

        this.mgmBldPk = bldInfoData.mgmBldPk;
        if (this.bldInfoData.mgmBldPk !== bldInfoData.mgmBldPk) {
          this.bldInfoData = bldInfoData;
        }

        this.$store
          .dispatch('main/FETCH_BUILD_MAPNG_GRADE_DATA', {
            dataCode: 'buildMapngGrade',
            mgmBldPk: bldInfoData.mgmBldPk,
            layerName: bldInfoData.layerId,
          })
          .then(() => {
            this.mapngGrade = this.$store.state.main.buildMapngGrade[0].mapng_grade;
            this.mapngGradeDesc = this.$store.state.main.buildMapngGrade[0].grade_cls3_desc;
            this.layerKorName = this.$store.state.main.buildMapngGrade[0].origin_name;
          })
          .catch(() => {
            this.mapngGrade = '-';
            this.mapngGradeDesc = '-';
            this.layerKorName = '등급판정불가';
          });

        // 선택한 건물 정보 저장
        await this.$store.dispatch('main/FETCH_BUILD_READ_HISTORY_DATA', {
          mgmBldPk: bldInfoData.mgmBldPk,
        });

        // 선택한 건물 내 건물로 등록한 내역 조회
        this.isStarred = await this.$store.dispatch('main/FETCH_BUILD_GET_BOOKMARK_DATA', {
          mgmBldPk: bldInfoData.mgmBldPk,
        });

        // 클릭시 기존 sotre 초기화
        this.$store.state.main.selectMenuComp = null;
      }
    },
    onClickMapArea(title, mapType, sigunguId, dongId) {
      this.modalMapAreaInfo = !this.modalMapAreaInfo;
      this.areaTitle = `▶ ${title} 통계 요약 (건물평균)`;
      this.sigunguId = sigunguId;
      this.dongId = dongId;
      this.mapType = mapType;
      // this.$store.dispatch("main/FETCH_MAP_AREA_DATA", this.mapId);
    },
    onClickEnergyUse(bldInfoData) {
      gis.search.moveToBldGeom(map, bldInfoData);
      // 전역변수 true
      this.buildClickTempInfo = true;
      // this.mgmBldPkArr.push(mgmBldPk)

      this.modalEnergyInfo = !this.modalEnergyInfo;
      this.mgmBldPk = bldInfoData.mgmBldPk;
      if (this.bldInfoData.mgmBldPk !== bldInfoData.mgmBldPk) {
        this.bldInfoData = bldInfoData;
      }
      // this.mgmBldPk = mgmBldPk
    },
    onClickNewEnergyUse(bldInfoData) {
      gis.search.moveToBldGeom(map, bldInfoData);
      // 전역변수 true
      this.buildClickTempInfo = true;
      // this.mgmBldPkArr.push(mgmBldPk)

      this.modalNewEnergyInfo = !this.modalNewEnergyInfo;
      this.mgmBldPk = bldInfoData.mgmBldPk;
      if (this.bldInfoData.mgmBldPk !== bldInfoData.mgmBldPk) {
        this.bldInfoData = bldInfoData;
      }
      // this.mgmBldPk = mgmBldPk
    },
    onClickKbossInfo(bldInfoData, mgmBldPkKboss, layer) {
      gis.search.moveToBldGeom(map, bldInfoData);
      this.mgmBldPk = mgmBldPkKboss;
      this.lyrIdStr = layer;
      this.modalKbossInfo = true;

      if (layer === 'buldFloorSurvey_KBOSS') {
        this.kbossTitle = '▶ KBOSS 설문조사 보기';
      } else if (layer === 'buldFloorSensor_KBOSS') {
        this.kbossTitle = '▶ KBOSS 센서측정 보기';
      } else if (layer === 'buldFloorZone_KBOSS') {
        this.kbossTitle = '▶ KBOSS 층별도면 보기';
      } else if (layer === 'buldFloorZone_F_FAC_BULD') {
        this.kbossTitle = '▶ 층(Zone)별 에너지소비량 보기';
      }
    },
    onClickLasso(mgmBldPkLasso) {
      this.modalLassoInfo = !this.modalLassoInfo;
      this.mgmBldPkLasso = mgmBldPkLasso;
    },
    onClickCommProc() {
      this.commonEnergy.useYyCd = this.mainSelected.useYyCd.cdId;
      this.commonEnergy.engyKindCd = this.mainSelected.engyKindCd.cdId;
      this.commonEnergy.clsfKindCd = this.mainSelected.clsfKindCd.cdId;
      this.commonEnergy.engyAllQtyCd = this.mainSelected.engyAllQtyCd.cdId;
      this.commonEnergy.engyUse = this.mainSelected.engyUse.cdId;

      if (this.mainSelected.MAIN_PURPS_CD) {
        this.mainSelected.MAIN_PURPS_CD.forEach((item) => {
          const temp = {};
          temp.prupsCd = item.cdId;

          if (!this.purpsArr.includes(item.cdId)) {
            this.purpsArr.push(item.cdId);
          }
          this.commonEnergy.prupsCd = this.purpsArr;
        });
      }
    },
    onClickEditOfferProc(mgmBldPk) {
      this.modalBuildInfoEditOffer = true;
      // this.mgmBldPkArr.push(mgmBldPk)

      // this.modalBuildInfoEditOffer = !this.modalBuildInfoEditOffer;
      this.mgmBldPk = mgmBldPk;
    },
    mover() {
      this.helpBoxShow = true;
    },
    mout() {
      this.helpBoxShow = false;
    },
    floorData(data) {
      if (data !== '') {
        gis.search.bldFloorCqlFilter(this.lyrIdStr, this.mgmBldPk, data);
      }
    },
    kbossEvt() {
      if (this.floorInfo !== '') {
        gis.search.bldFloorCqlFilter(this.lyrIdStr, this.mgmBldPk, this.floorInfo);
      }
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },

    handleSubmit() {
      utils.fnConfirm(this, '등록하시겠습니까?', '').then(async (res) => {
        if (res.value) {
          // 사용자가 확인 눌럿을 경우
          // 유효성 검사
          if (this.buildInfoEditOffer.suggestCd.length === 0) {
            alert('관련정보를 선택해 주세요.');
            return;
          }

          if (this.buildInfoEditOffer.suggestText === '') {
            alert('제안사항을 입력해 주세요.');
            return;
          }

          const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

          if (this.buildInfoEditOffer.suggestEmail.match(regExp) == null) {
            alert('이메일 양식이 올바르지 않습니다.');
            return;
          }

          if (this.buildInfoEditOffer.suggestEmailYn === '') {
            alert('개인정보 수집 동의여부를 선택해 주세요.');
            return;
          }

          this.buildInfoEditOffer.mgmBldPk = this.mgmBldPk;
          await this.$store.dispatch('filter/SAVE_FILTER_EDIT_OFFER', this.buildInfoEditOffer);
          this.modalBuildInfoEditOffer = !this.modalBuildInfoEditOffer;
          alert('등록되었습니다.');
        }
      });
    },
    async onClickPdfBEPA(mgmBldPk) {
      // BEPA 대상 건물인지 조회
      const res = await this.$store.dispatch('main/FETCH_PDF_BEPA_EXIST', {
        mgmBldPk,
      });
      if (res) {
        this.modalPdfBEPA = !this.modalPdfBEPA;
        this.mgmBldPk = mgmBldPk;
      } else {
        alert('BEPA 리포트가 없습니다.');
      }
    },
    async toggleStarred(isStarred) {
      await this.$store.dispatch('main/FETCH_BUILD_SET_BOOKMARK_DATA', {
        mgmBldPk: this.mgmBldPk,
        bookmarkYn: (this.isStarred = !isStarred),
      });
      this.isStarred = !isStarred;
    },
    async onClickBuildTotal(mgmBldPk) {
      // 클릭시 기존 sotre 초기화
      this.$store.state.main.selectMenuComp = null;

      // modal 타이틀에 건물정보, 주소를 입력하기 위해 db 조회
      const res = await this.$store.dispatch('main/FETCH_BUILD_TOTAL_TITLE_DATA', { mgmBldPk });

      this.modalBuildTotal = !this.modalBuildTotal;
      this.buildTotalInfo.bldNm = res.bldnm;
      this.buildTotalInfo.bldAddr = res.roadplataddr;
      this.buildTotalInfod.mgmBldPk = res.mgmbldpk;
    },
    async onClickSearchBld() {
      await this.$store.dispatch('main/FETCH_BUILD_SEARCH_BLD_DATA', !this.$store.state.main.inputSearch);
    },
    buldLayerBtnClk(event) {
      this.buldLayerFlag = !this.buldLayerFlag;
    },
    changeBgMap(event) {
      document.getElementById('icon_roadaddr').src = '/gis/map/common/images/gis/icon_roadaddrmap_off.png';
      document.getElementById('icon_kakao').src = '/gis/map/common/images/gis/icon_kakaomap_off.png';
      document.getElementById('icon_vworld').src = '/gis/map/common/images/gis/icon_vworldmap_off.png';
      document.getElementById('icon_ktmap').src = '/gis/map/common/images/gis/icon_ktmap_off.png';
      document.getElementById('icon_baroemap').src = '/gis/map/common/images/gis/icon_baroemap_off.png';
      let element = '';

      if (event.target.tagName === 'DIV') {
        element = event.target.querySelector('.icon').getAttribute('id');
      } else {
        element = event.target.parentNode.querySelector('.icon').getAttribute('id');
      }
      const originSrc = document.getElementById(element).src;
      document.getElementById(element).src = `${originSrc.slice(0, -8)}.png`;
    },
    changeBuldLayerVal(event) {
      const buldLayersItem = document.getElementsByClassName('buldLayersItem');
      const buldLayerTitle = document.getElementsByClassName('buldLayerTitle');
      const buldLayerExp = document.getElementsByClassName('buldLayerExp');

      buldLayersItem.forEach((e) => {
        e.style.background = '#f0f0f0';
      });
      buldLayerTitle.forEach((e) => {
        e.style.color = '#000';
      });
      buldLayerExp.forEach((e) => {
        e.style.color = '#a0a0a0';
      });

      let selectedElementId = '';

      if (event.target.classList[0] === 'buldLayerItemWrap') return;

      if (event.target.id === '') {
        selectedElementId = event.target.parentNode.id;
      } else {
        selectedElementId = event.target.id;
      }

      const eventGen = new Event('change');
      const parentNode = document.getElementById(selectedElementId);
      document.getElementById('buldLayers').value = selectedElementId;
      document.getElementById('buldLayers').dispatchEvent(eventGen);

      document.getElementById(selectedElementId).style.background = '#4441d4';
      parentNode.getElementsByClassName('buldLayerTitle')[0].style.color = '#fff';
      parentNode.getElementsByClassName('buldLayerExp')[0].style.color = '#fff';
    },
    // 	changeLasso(event) {
    //     document.getElementById('lasso_building').src = "/gis/map/common/images/gis/renew_icon_building_off.png";
    // 		document.getElementById('lasso_circle').src = "/gis/map/common/images/gis/renew_icon_circle_off.png";
    // 		document.getElementById('lasso_square').src = "/gis/map/common/images/gis/renew_icon_square_off.png";
    // 		document.getElementById('lasso_polygon').src = "/gis/map/common/images/gis/renew_icon_polygon_off.png";
    // 		document.getElementById('lasso_all').src = "/gis/map/common/images/gis/renew_icon_all_off.png";

    // 		const msgElList = document.getElementsByClassName('lassoMsg');
    //     msgElList.forEach((el) => { el.classList.remove('on'); });

    //     let imgEl = '';
    // 		let msgEl = '';
    // 		if (event.target.tagName === 'DIV') {
    // 			imgEl = event.target.querySelector('.lassoIconSelector').getAttribute('id');
    // 			msgEl = event.target.querySelector('.lassoMsg');
    // 		} else {
    // 			imgEl = event.target.parentNode.querySelector('.lassoIconSelector').getAttribute('id');
    // 			msgEl = event.target.parentNode.querySelector('.lassoMsg');
    // 		}
    // 		const originSrc = document.getElementById(imgEl).src;
    //     document.getElementById(imgEl).src = originSrc.replace('_off.png', '_on.png');
    //     msgEl.classList.add('on');

    // 	},
    async mapAreaExcel() {

      utils.fnConfirm(this, '다운로드 하시겠습니까?', '엑셀 다운로드시 일정 시간이 소요될 수 있습니다.')
        .then(async result => {
          if (result.value) {
            const aJson = {};

            aJson.mainPurpsCd = this.mainSelected.MAIN_PURPS_CD;

            aJson.sigunguId = [{
              sigunguId: this.sigunguId,
            }];

            if(this.dongId) {
              aJson.dongId = [{
                dongId: this.dongId,
              }];
            }

            aJson.useYyCd = [{
              cdId: this.mainSelected.useYyCd.cdId
            }];


            const result = await this.$store.dispatch("filter/DOWNLOAD_EXCEL", aJson);
            utils.fnAlert(this, result.resultMsg);
            // alert(`처리 결과: ${result.resultMsg}`);

            // if (result.resultFlag) {
            //   utils.fnAlert(this, this.result.resultMsg);
            // }
          }
        });

    },
  },
};
</script>

<style lang="scss">
.container {
  width: 100% !important;
  margin: 0;
  padding: 0;
  max-width: none !important;
}

.menuHelp {
  position: relative;
  top: -3px;
  margin-left: 5px;
  margin-right: 12px;
  cursor: pointer;

  &Box {
    border: 2px solid #1560ad;
    width: 525px;
    padding: 10px;
    display: inline-block;
    position: absolute;
    top: 45px;
    right: 30px;
    z-index: 99;
    background: white;
    font-size: 14px;
    font-weight: normal;
    border-radius: 10px;
    //font-family: 'NSK', 맑은고딕, 'Malgun Gothic', 돋움, Dotum, sans-serif;
  }
}

.left-div {
  width: 65.8%;
}
.modal-xl {
  max-width: 70% !important;
}
.gradeDiv {
  display: flow-root;
}

#featureTooltipInfo {
  position: absolute;
  height: 1px;
  width: 1px;
  z-index: 100;
}
.tooltip.in {
  opacity: 1;
}
.tooltip.top .tooltip-arrow {
  border-top-color: white;
}
.tooltip-inner {
  border: 2px solid white;
}
#kbossPopup {
  top: 30%;
  left: 70%;
  min-width: 500px;
  width: auto;
  height: 270px;
  transform: translate(-50%, -50%);
}
</style>
